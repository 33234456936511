const appConstant = {
  TOKEN_KEY: "car_manager_access_token",
};

export const errors = {
  "error.validate.car.already-exist": "Xe đã tồn tại",
  "contract.validate.car.contract-exist": "Xe đã có hợp đồng",
};

export default appConstant;
